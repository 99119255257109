import React, { useEffect } from "react"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"

const ProjectList = ({
  projects,
  pageName,
  handleInViewColor,
  handleNavigateToProject,
  handleCenterArticle,
  locationHash,
}) => {
  let list = projects.map((project, id) => (
    <li key={`list${id}`}>
      <ProjectListItem
        project={project}
        id={`${id}`}
        pageName={pageName}
        handleInViewColor={handleInViewColor}
        handleNavigateToProject={handleNavigateToProject}
      />
    </li>
  ))
  return <ul>{list}</ul>
}

const ProjectListItem = ({
  project,
  id,
  pageName,
  handleInViewColor,
  handleNavigateToProject,
}) => {
  const [ref, inView] = useInView()
  let ratio,
    fluid,
    posterSrc = null

  if (project.node.frontmatter.type === "video") {
    ratio =
      project.node.frontmatter.videos[0].poster.childImageSharp.fluid
        .aspectRatio
    fluid = project.node.frontmatter.videos[0].poster.childImageSharp.fluid
    posterSrc =
      project.node.frontmatter.videos[0].poster.childImageSharp.fluid.src
  } else {
    if (project.node.frontmatter.poster != null) {
      ratio = project.node.frontmatter.poster.childImageSharp.fluid.aspectRatio
      fluid = project.node.frontmatter.poster.childImageSharp.fluid
      posterSrc = project.node.frontmatter.poster.childImageSharp.fluid.src
    }
  }

  useEffect(() => {
    if (inView) {
      handleInViewColor(id)
    }
  }, [inView])

  let projectLink = `/projects/${project.node.fields.slug}?cat=${pageName}&pro=${id}`

  // console.log(id,articleBodyBox);
  return (
    <article
      className="mb-32 lg:mb-64 2xl:mb-96"
      key={id}
      id={`acticel${id}`}
      ref={ref}
      style={{ position: "relative" }}
    >
      <div
        onClick={() => handleNavigateToProject(projectLink)}
        onKeyDown={() => handleNavigateToProject(projectLink)}
        role={"button"}
        style={{
          width: "90vw",
          transition: "all 0.5s ease",
          display: "inline",
          cursor: "pointer",
        }}
        tabIndex={id}
        id={id}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
          className="zoomin"
        >
          <p
            style={{ zIndex: 2 }}
            className="text-2xl sm:text-4xl lg:text-5xl 3xl:text-9xl font-serif leading-supertight tracking-tight text-brand-white text-center absolute"
          >
            {project.node.frontmatter.client ? (
              <span>
                {project.node.frontmatter.client}
                <br></br>
                {project.node.frontmatter.title}
              </span>
            ) : (
              <span>{project.node.frontmatter.title}</span>
            )}
          </p>

          {project.node.frontmatter.type === "image" ? (
            <div
              className="transition-all ease-in-out"
              style={{
                width:
                  ratio < 1
                    ? `calc( 90% - ${ratio * 20}%)` // Image width is inversely proportionate to ratio
                    : `calc( 70% + ${ratio * 20}%)`,
                overflow: "hidden",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Img
                style={{
                  margin: "auto",
                  objectFit: "contain",
                  width:
                    ratio < 1
                      ? `calc( 90% - ${ratio * 20}%)` // Image width is inversely proportionate to ratio
                      : `calc( 70% + ${ratio * 20}%)`,
                }}
                fluid={fluid}
                alt={project.node.frontmatter.poster.name}
              />
            </div>
          ) : (
            <div
              className={
                "video-container transition-all duration-200 ease-in-out"
              }
            >
              <video
                className={"video"}
                style={{
                  transition: "all 5sec ease",
                  objectFit: "fit",
                }}
                preload={"metadata"}
                poster={posterSrc}
                src={project.node.frontmatter.videos[0].url}
                autoPlay
                muted
                loop
              ></video>
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

export default ProjectList
