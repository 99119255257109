import React, { useState, useEffect } from "react"
import SEO from "../components/Seo"
import ProjectList from "./ProjectList"
import { useInView } from "react-intersection-observer"
import { rgba2Rgb, hex2Rgba, cleanPath } from "../utils"

const PageWrapper = ({
  path,
  data,
  children,
  handleNavigateToProject,
  inViewColorHandler,
  handlePageFooterFade,
  location,
}) => {
  const projects = data.allMarkdownRemark.edges
  const [pageName, setPageName] = useState(
    cleanPath(path) === "" ? "featured" : cleanPath(path)
  )
  useEffect(() => {
    let myPath = cleanPath(path)
    if (cleanPath(path) === "") {
      myPath = "featured"
    }
    if (myPath !== pageName) {
      setPageName(myPath)
    }
  }, [pageName, path])

  const [slugColor, setSlugColor] = useState()
  // Building the posterColorsArray
  const [posterColorsArray, setPosterColorsArray] = useState([])

  useEffect(() => {
    let temp = projects.map(project => {
      if (
        project.node.frontmatter.type === "video" &&
        project.node.frontmatter.videos[0]
      ) {
        let tempBgColor = hex2Rgba(
          `${project.node.frontmatter.videos[0].poster.colors.vibrant}65`
        )
        tempBgColor = rgba2Rgb(tempBgColor)
        return {
          background: `${tempBgColor}`,
          text: `${project.node.frontmatter.videos[0].poster.colors.vibrant}`,
        }
      } else {
        if (project.node.frontmatter.poster) {
          let tempBgColor = hex2Rgba(
            `${project.node.frontmatter.poster.colors.vibrant}65`
          )
          tempBgColor = rgba2Rgb(tempBgColor)
          return {
            background: `${tempBgColor}`,
            text: `${project.node.frontmatter.poster.colors.vibrant}`,
          }
        } else {
          return {
            background: `#fff`,
            text: `white`,
          }
        }
      }
    })
    setPosterColorsArray(temp)
  }, [projects])

  const [enter, setEnter] = useState(0)
  const [exit, setExit] = useState(1)

  const handleInViewColor = number => {
    if (enter !== number) {
      setEnter(number)
    }
  }

  useEffect(() => {
    if (enter !== exit) {
      if (posterColorsArray.length > 0) {
        if (enter < posterColorsArray.length) {
          // console.log("change color", enter, exit, posterColorsArray)
          let backgroundColor = posterColorsArray[enter].background
          let textColor = posterColorsArray[enter].text
          inViewColorHandler({ backgroundColor, textColor })
          setSlugColor(textColor)
          setExit(enter)
        }
      }
    }
  }, [exit, enter, posterColorsArray, inViewColorHandler])

  const [footerMarkerRef, footerMakerInView] = useInView()
  useEffect(() => {
    if (footerMakerInView) {
      handlePageFooterFade(footerMakerInView)
    } else {
      handlePageFooterFade(false)
    }
  }, [footerMakerInView, handlePageFooterFade])

  return (
    <React.Fragment>
      <main className="container 3xl:max-w-screen-3xl pb-12 sm:pb-24">
        {pageName && (
          <h1
            className="sticky text-xs lg:text-sm text-center font-semibold tracking-widest uppercase"
            style={{
              top: "50vh",
              color: slugColor ? slugColor : "#000",
              transition: "all 4s ease",
            }}
          >
            {pageName}
          </h1>
        )}
        <div className="p-5 sm:p-18 md:px-24 lg:px-18 xl:px-56 2xl:px-64 3xl:p-48">
          <SEO title={`${pageName}`} />
          <section>
            <ProjectList
              projects={projects}
              pageName={pageName}
              handleInViewColor={handleInViewColor}
              handleNavigateToProject={handleNavigateToProject}
            />
          </section>
        </div>
      </main>
      <div
        ref={footerMarkerRef}
        id="footermarker"
        style={{ transform: "translateY(1px)" }}
      ></div>
    </React.Fragment>
  )
}

export default PageWrapper
